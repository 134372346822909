<template>
  <div id="MessageDetail" class="wrap">
    <div class="formWrap">
      <h1 class="title">メッセージ</h1>
      <div class="inputBox">
        <b-row class="mt-4 mx-4">
          <div class="label p-0 mb-0 mr-10px">
            <label v-if="detailType === 'inbox'" class="mb-0 bold">受信日</label>
            <label v-else class="mb-0 bold">送信日</label>
          </div>
          <div class="mb-1 mainArea">
            <span class="dataText">
              {{ sendDate }}
            </span>
          </div>
        </b-row>
      <hr/>
        <b-row class="mt-4 mx-4">
          <div class="label p-0 mb-0 mr-10px">
            <label v-if="detailType === 'inbox'" class="mb-0 bold">送信者</label>
            <label v-else class="mb-0 bold">宛先</label>
          </div>
          <div class="mb-1 mainArea">
            <span class="dataText">
              {{ recipientTarget }}
            </span>
          </div>
        </b-row>
      <hr/>
      <b-row class="mt-4 mx-4">
        <div class="label p-0 mb-0 mr-10px">
          <label class="mb-0 bold">添付ファイル1</label>
        </div>
        <div class="mb-1 mainArea">
          <b-col class="p-0">
            <span v-if="fileList[0]">
              <b-link @click="downloadFile(fileList[0].file_no, fileList[0].file_name)">{{fileList[0].file_name}}</b-link>
            </span>
          </b-col>
        </div>
      </b-row>
      <hr/>
      <b-row class="mt-4 mx-4">
        <div class="label p-0 mb-0 mr-10px">
          <label class="mb-0 bold">添付ファイル2</label>
        </div>
        <div class="mb-1 mainArea">
          <b-col class="p-0">
            <span v-if="fileList[1]">
              <b-link @click="downloadFile(fileList[1].file_no, fileList[1].file_name)">{{fileList[1].file_name}}</b-link>
            </span>
          </b-col>
        </div>
      </b-row>
      <hr/>
      <b-row class="mt-4 mx-4 line">
        <div class="label p-0 mb-0 mr-10px">
          <label class="mb-0 bold">件名</label>
        </div>
        <div class="mb-1 mainArea">
          <span class="dataText">
            {{ institution }}
          </span>
        </div>
      </b-row>
      <hr/>
      <b-row class="mt-4 mx-4">
        <div class="label p-0 mb-0 mr-10px">
          <label class="mb-0 bold">本文</label>
        </div>
        <div class="mb-1 mainArea messageWrap">
          <span class="dataText">{{message}}</span>
        </div>
      </b-row>
      </div>
    </div>

    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        class="btn back bold"
        @click="$router.push({ name: 'gasMessageInbox' })"
      >
        一覧に戻る
      </b-button>
      <b-button
        v-if="detailType === 'inbox'"
        @click="goReplying(msgId)"
        variant="info"
        class="btn btn-primary btn-lg ml-4 mr-4 bold">
        返信
      </b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import CONST_MESSAGE_SETTING from '@/constants/userMsgSetting';
import api from '@/modules/api';
import download from '@/modules/download';

export default {
  name: 'gasMessageCreate',
  components: {

  },
  data() {
    return {
      msgId: null,
      detailData: [],
      sendDate: null,
      fileList: [],
      institution: '',
      message: '',
      recipientTarget: '',
      detailType: '',
    };
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    const { msgId } = this.$route.params;
    const { detail } = this.$route.meta;
    this.msgId = msgId;
    const param = {
      msgId,
      detail,
    };
    const response = await api.send('/api/message/detail', param)
      .catch((err) => {
        console.log(err);
      });
    this.detailType = response.data.isRecieved ? 'inbox' : 'outbox';
    this.detailData = response.data.detail;
    if (this.detailData) {
      this.recipientTarget = this.detailType === 'inbox' ? this.detailData.from_name : this.detailData.to_name;
      const titleType = (this.detailData.title_type in this.titleList) ? this.titleList[this.detailData.title_type] : this.titleList['99'];
      this.institution = titleType === this.titleList['99'] ? `${titleType} - ${this.detailData.title}` : titleType;
      this.message = this.detailData.text;
      this.sendDate = moment(this.detailData.created_at).format('YYYY/MM/DD HH:mm');
      if (this.detailData.files.length > 0) {
        this.fileList = this.detailData.files;
      }
    }
    this.$store.dispatch('page/offLoading');
  },
  computed: {
    recipientList() {
      return CONST_MESSAGE_SETTING.ADMIN_SEND_TARGET_TEXTS;
    },
    titleList() {
      return CONST_MESSAGE_SETTING.MESSAGE_TITLE_TEXTS;
    },

  },
  methods: {
    goReplying(id) {
      this.$router.push(`/message/inbox/replying/${id}`);
    },
    initParams() {
      this.fileList = [];
      this.fileList2 = [];
      this.institution = '';
      this.message = '';
      this.recipientTarget = 0;
    },
    async downloadFile(fileNo, fileName) {
      const { msgId } = this.$route.params;
      const param = {
        msg_id: msgId,
        file_no: fileNo,
        type: this.detailType,
      };
      const url = '/api/message/fileDownload';

      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      this.$store.commit('gasApplicationInput/setAnsNum', 1);
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
  },
};
</script>
<style scoped>
  .messageWrap span{
    white-space: pre-wrap;
  }
  .title{
    color: #333333;
    font-weight: 300;
    font-size: 24px;
    margin: 0 100px;
    margin-top: 52px;
    border-bottom: solid 2px #A0A9D0;
    padding-left: 10px;
    padding-bottom: 7px;
  }
  .inputBox{
    margin: 0 100px;
    margin-top: 53px;
  }
  .mainArea {
    width: 770px;
  }
  input {
    height: 50px;
  }
  .label {
    width: 230px;
    max-width: 230px;
    height: 25px;
  }
  .line {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .drop {
    height: 100px;
    width: 100%;
    background-color: #F3F3F3;
    border-color: #CCC;
    border-style: dashed;
    border-radius: 10px;
    border-width: 1px;
  }
  .dragText {
    color: #BBB;
  }
  .fileText {
    color: #0A8EA7;
  }
  .is-dragging.drop {
    background-color: #E6E6E6;
    border-color: #6E6E6E;
  }
  .is-dragging {
    color: #6E6E6E;
  }
  .selectFile {
    cursor: pointer;
  }
  .selectFile:hover {
    text-decoration: underline;
  }
  .recipient-select {
    width: auto;
    min-width: 150px;
  }
</style>
